<template>
  <div>
    <b-row>
      <b-col md="8">
        <h2 class="mb-1">Rapport journalier des activités</h2>
      </b-col>
      <!-- <b-col md="4">
        <b-form-datepicker
          id="datepicker"
          v-model="value"
          placeholder="Sélectionnez une date"
          class="mb-1"
      /></b-col> -->
    </b-row>

    <b-overlay :show="showLoading" rounded="sm">
      <b-card v-if="!hasActivity">
        <small>Aucune activité</small>
      </b-card>

      <b-card v-else v-for="task in _tasks" :key="task.id">
        <template #header>
          <div>
            <h2 class="mb-1">{{ task.name }}</h2>
          </div>
        </template>

        <app-timeline>
          <app-timeline-item
            v-for="item in task.changelogs"
            :key="item.id"
            :title="item.title"
            :subtitle="item.description"
            :time="_time(item.created_at)"
            variant="success" 
          />
        </app-timeline>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AppTimeline from "@/@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import moment from "moment";
import { BOverlay } from "bootstrap-vue";
export default {
  data() {
    return {
      tasks: [],
      showLoading: true,
      value: "",
    };
  },

  components: {
    AppTimeline,
    AppTimelineItem,
    BOverlay,
  },

  methods: {
    ...mapActions("tasks", ["myActivities"]),
    _time(time) {
      return moment(time).locale("fr").fromNow();
    },
  },

  computed: {
    _tasks() {
      return this.tasks.filter((item) => item.changelogs.length != 0);
    },

    hasActivity() {
      return this._tasks.some((item) => item.changelogs.length != 0);
    },
  },

  mounted() {
    this.myActivities().then((r) => {
      this.showLoading = false;
      this.tasks = r.data.data;
    });
  },
};
</script>